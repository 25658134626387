import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const details = [
  {
    heading: "Currently at",
    description: "Department of Agriculture, Fisheries and Forestry",
    class: "current-job",
  },
  {
    heading: "About",
    description: "I’m an interaction designer, mostly but not exclusively for things on screen, with an interest in working on things that solve real problems. I especially enjoy designing things that respond to a complex problem space, and yet, achieve a clarity of form and function. My experience in the areas of research, service design and technology helps me consider the needs of individuals, organisations and systems together.",
    class: "about",
  },
  {
    heading: "Previously at",
    description: "Digital Transformation Agency (2021), Craig Walker (2016-2021), Nine Digital (2014-2016)",
    class: "previous-jobs",
  },
  {
    heading: "Worked on projects for clients including:",
    description: "ANZ, Commonwealth Bank, Westpac, Facebook, TTC Labs, University of Technology Sydney, City of Sydney, Fairfax Media, Singapore Health Promotion Board",
    class: "clients",
  },
  {
    heading: "Examples of work",
    description: "Available upon request",
    class: "projects"
  },
]

const IndexPage = () => (
  <Layout>
    <title>Ryan Phung - Interaction Designer</title>
    <section className="title">
      <h1>
      Ryan Phung
      <br />
      <span>Interaction Designer</span>
      </h1>
    </section>

    <section className="contact">
      <h2>Contact</h2>
      <p>
        <a href="mailto:ryan.e.phung@gmail.com">ryan.e.phung@gmail.com</a>
      </p>
    </section>

    {details.map(detail => (
      <section key={detail.url} className={detail.class}>
        <h2>{detail.heading}</h2>
        <p>{detail.description}</p>
      </section>
    ))}

    <span className="smiley">☻</span>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
